import React from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
//import { colourOptions } from "./data";

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

export default function MultiSelect(props) {
  const {
    create = true,
    options = [],
    multiple = false,
    onValueSelect,
    name = "",
    selected = [],
  } = props;
  return (
    //  <Select
    //    closeMenuOnSelect={false}
    //    //components={{ ClearIndicator }}
    //    styles={{ clearIndicator: ClearIndicatorStyles }}
    //    defaultValue={selected}
    //    isMulti={multiple}
    //    options={options}
    //    //onSelect={(e) => console.log(e)}
    //    //onSelectCapture={(e) => console.log(e)}
    //    onChange={(value) => onValueSelect(value, name)}
    //  />

    <>
      {create && (
        <CreatableSelect
          //ref={selectRef}
          //closeMenuOnSelect={false}
          //components={{ ClearIndicator }}
          defaultValue={selected}
          //value={selected ? selected : null}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          //defaultValue={selected}
          isMulti={multiple}
          isClearable={true}
          options={options}
          onChange={(value) => onValueSelect(value, name)}
        />
      )}
      {!create && (
        <Select
          //ref={selectRef}
          //closeMenuOnSelect={false}
          //components={{ ClearIndicator }}
          //defaultValue={filterOptionValue()}
          //value={selected ? selected : null}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          //defaultValue={selected}
          defaultValue={selected}
          isMulti={multiple}
          isClearable={true}
          options={options}
          onChange={(value) => onValueSelect(value, name)}
        />
      )}
    </>
  );
}
