import axiosInstance from "./AxiosInstance";

export function getProfile(id) {
  return axiosInstance.get(`recruiter/candidates/${id}`);
}

export function addNotes(data) {
  return axiosInstance.post(`recruiter/candidates/add-note`, data);
}

export function getNotes(candidate_id) {
  let data = { candidate_id };
  return axiosInstance.post(
    `recruiter/candidates/get-notes`,
    data
  );
}

// export function updateStatus(type, data) {
//   let cadidateType = type === "unlock" ? "unlock" : "applicant";
//   return axiosInstance.get(
//     `recruiter/candidates/${cadidateType}/profile-status`,
//     data
//   );
// }

export async function userProfile(){
   return axiosInstance.get("recruiter/user/recruiter-details");
}
