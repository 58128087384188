import { sidebarAPI } from "../../jsx/api/menu";
// import {
//   formatError,
//   login,
//   runLogoutTimer,
//   saveTokenInLocalStorage,
//   signUp,
// } from "../../services/AuthService";
import swal from "sweetalert";

export const SET_MENU = "set menu";

export function sidebarMenuAction(rid) {
  return (dispatch) => {
    sidebarAPI(rid)
      .then((response) => {
        dispatch(setMenuAction(response));
      })
      .catch((error) => {
        //console.log(error);
        swal("Error", error?.message, "error");
      });
  };
}

export function setMenuAction(payload) {
  return {
    type: SET_MENU,
    payload,
  };
}

// export function signupAction(email, password, navigate) {
//   return (dispatch) => {
//     signUp(email, password)
//       .then((response) => {
//         saveTokenInLocalStorage(response.data);
//         runLogoutTimer(
//           dispatch,
//           response.data.expiresIn * 1000
//           //history,
//         );
//         dispatch(confirmedSignupAction(response.data));
//         navigate("/dashboard");
//         //history.push('/dashboard');
//       })
//       .catch((error) => {
//         const errorMessage = formatError(error.response.data);
//         dispatch(signupFailedAction(errorMessage));
//       });
//   };
// }

// export function Logout(navigate) {
//   localStorage.removeItem("userDetails");
//   navigate("/login");
//   //history.push('/login');

//   return {
//     type: LOGOUT_ACTION,
//   };
// }

// export function loginAction(email, password, navigate) {
//   return (dispatch) => {
//     login(email, password)
//       .then((response) => {
//         saveTokenInLocalStorage(response.data?.data);
//         runLogoutTimer(
//           dispatch,
//           response.data?.data?.expiresIn * 1000,
//           navigate
//         );
//         dispatch(loginConfirmedAction(response.data?.data));
//         //dispatch(dashboard());
//         navigate("/dashboard");
//       })
//       .catch((error) => {
//         if (error.response.data?.message !== undefined) {
//           console.log(error.response.data?.message);
//           //const errorMessage = formatError(error.response.data?.message);
//           swal("Oops", error.response.data?.message, "error");
//           dispatch(loginFailedAction(error.response.data?.message));
//         } else {
//           //const errorMessage = formatError(error.response.data?.message);
//           dispatch(loginFailedAction(error?.message));
//           swal("Error", error?.message, "error");
//         }
//       });
//   };
// }

// export function loginFailedAction(data) {
//   return {
//     type: LOGIN_FAILED_ACTION,
//     payload: data,
//   };
// }

// export function loginConfirmedAction(data) {
//   return {
//     type: LOGIN_CONFIRMED_ACTION,
//     payload: data,
//   };
// }

// export function confirmedSignupAction(payload) {
//   return {
//     type: SIGNUP_CONFIRMED_ACTION,
//     payload,
//   };
// }

// export function signupFailedAction(message) {
//   return {
//     type: SIGNUP_FAILED_ACTION,
//     payload: message,
//   };
// }

// export function loadingToggleAction(status) {
//   return {
//     type: LOADING_TOGGLE_ACTION,
//     payload: status,
//   };
// }
