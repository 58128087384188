import React, { Fragment, useCallback, useEffect, useState } from "react";
import CounterCard from "./Dashboard/CounterCard";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  callGetDashboardCount,
  updateProfileAction,
} from "../../../store/actions/DashboardActions";
import { offerPopup } from "../../../services/BillingHistory";

export default function Dashboard() {
  const counts = useSelector((state) => state.dashboard);
  const { roleName, is_show_popup = false } = useSelector(
    (state) => state.auth?.auth
  );
  const dispatch = useDispatch();
  const [offer, setOffer] = useState({ offer: null });

  useEffect(() => {
    dispatch(callGetDashboardCount());
    //dispatch(sidebarMenuAction());
  }, [dispatch]);

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      offerPopup(offer)
        .then((response) => {
          if (response?.data?.code === "success") {
            dispatch(updateProfileAction());
            setOffer({ offer: null });
            swal("Great Job!", response?.data.message, "success");
          } else {
            swal("Opps", response?.data.message, "error");
          }
        })
        .catch((e) => console.log(e));
    },
    [offer, setOffer]
  );

  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Your Applicants</h2>
          <div className="row">
            <CounterCard
              title={counts?.total_unlock_quota}
              subtitle="Total Unlock Allotted"
              link=""
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={counts?.general_unlock_quota}
              subtitle="Database Unlock Allotted"
              link=""
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={counts?.applicants_unlock_quota}
              subtitle="Applicant Unlock Allotted"
              link=""
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={counts?.total_unlocked_remaining}
              subtitle="Total Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={counts?.general_unlocked_remaining}
              subtitle="Database Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={counts?.applicants_unlocked_remaining}
              subtitle="Applicant Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={
                counts?.total_unlock_quota - counts?.total_unlocked_remaining
              }
              subtitle="Total Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={
                counts?.general_unlock_quota -
                counts?.general_unlocked_remaining
              }
              subtitle="Database Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={
                counts?.applicants_unlock_quota -
                counts?.applicants_unlocked_remaining
              }
              subtitle="Applicant Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={counts?.start_subscription}
              subtitle="Subscription Start Date"
              link=""
              iconClass="fas fa-calendar-check"
              cssClass="success"
            />
            <CounterCard
              title={counts?.expire_date}
              subtitle="Subscription End Date"
              link=""
              iconClass="fas fa-calendar-times"
              cssClass="danger"
            />
            <CounterCard
              title={counts?.expire_date_days}
              subtitle="Balance Days"
              link=""
              iconClass="fas fa-clock"
              cssClass="warning"
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Your Jobs</h2>
          <div className="row">
            <CounterCard
              title={counts?.total_jobs}
              subtitle="Total Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard-list"
              cssClass="primary"
            />
            <CounterCard
              title={counts?.live_on_site}
              subtitle="Live Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard-check"
              cssClass="success"
            />
            <CounterCard
              title={counts?.remaining_jobs}
              subtitle="Job Post Balance"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="warning"
            />
            <CounterCard
              title={counts?.applicants}
              subtitle="Total Applicants"
              link=""
              iconClass="fas fa-users"
              cssClass="dark"
            />
            <CounterCard
              title={counts?.draft_jobs}
              subtitle="Draft jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="info"
            />
            <CounterCard
              title={counts?.hot_jobs}
              subtitle="Hot Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="warning"
            />
            <CounterCard
              title={counts?.tech_jobs}
              subtitle="Tech jobs"
              link="/manage-jobs"
              iconClass="fas fa-gears"
              cssClass="primary"
            />
            <CounterCard
              title={counts?.non_tech_jobs}
              subtitle="Non-tech Jobs"
              link="/manage-jobs"
              iconClass="fas fa-check-circle"
              cssClass="secondary"
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Sub Recruiters</h2>
          <div className="row">
            <CounterCard
              title={counts?.sub_recruiter_quota}
              subtitle="Total Sub Recruiters Allotted"
              link={roleName === "Sub Recruiter" ? "" : "/manage-sub-recruiter"}
              iconClass="fas fa-user-tag"
              cssClass="info"
            />
            <CounterCard
              title={counts?.subrecruiter_remaining_quota}
              subtitle="Remaining Sub Recruiters"
              link={roleName === "Sub Recruiter" ? "" : "/manage-sub-recruiter"}
              iconClass="fas fa-user"
              cssClass="warning"
            />
            <CounterCard
              title={counts?.active_sub_recruiter ?? 0}
              subtitle="Active Sub Recruiters"
              link={roleName === "Sub Recruiter" ? "" : "/manage-sub-recruiter"}
              iconClass="fas fa-user"
              cssClass="success"
            />
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        size="lg"
        show={is_show_popup}
        //onHide={setStatusModal}
        keyboard={false}
        backdrop="static"
      >
        <div role="document">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Offers Made</h4>
          </div>
          <form onSubmit={handleAdd}>
            <div className="modal-body">
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="mb-3 form-group">
                        <label htmlFor="job_title">
                          Offers This Week
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          id="offer"
                          onChange={(e) =>
                            setOffer({
                              offer: e.target.value,
                            })
                          }
                          className="form-control"
                          type="text"
                          name="offer"
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-success me-2"
                //disabled={imageUploading.uploding}
              >
                <i className="fa fa-save"></i> Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
}
