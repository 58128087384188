import React, { useReducer, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
import profile from "../../../images/profile/pic1.jpg";
import { sidebarAPI } from "../../api/menu";
import { sidebarMenuAction } from "../../../store/actions/MenuActions";
import { useDispatch, useSelector } from "react-redux";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

// const initialState = {
//   active: "",
//   activeSubmenu: "",
// };

const SideBar = () => {
  const location = useLocation();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const initialState = {
    active:
      location?.pathname === "/" ? "dashboard" : (location?.pathname).slice(1),
    activeSubmenu: "",
  };

  const [state, setState] = useReducer(reducer, initialState);
  //const [menuList, setMenuList] = useState([]);
  const [profileDetail, setProfileDetail] = useState({});

  const dispatch = useDispatch();

  const menuList = useSelector((state) => state.menu.menu);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    let pdata = JSON.parse(localStorage.getItem("userDetails"));
    setProfileDetail(pdata);
    //fetchMenuItems(pdata.rid);
    //dispatch(sidebarMenuAction(pdata.rid))
  }, [dispatch]);

//   const fetchMenuItems = (rid) => {
//     try {
//       //sidebarMenuAction();
//       sidebarAPI(rid)
//         .then((res) => {
//           setMenuList(res);
//         })
//         .catch((e) => {
//           //console.error("Error fetching menu items: ", e.message);
//           swal("Error", e?.message, "error");
//         });
//       //
//     } catch (error) {
//       //console.error("Error fetching menu items:", error);
//       swal("Error", error?.message, "error");
//     }
//   };

  // let handleheartBlast = document.querySelector(".heart");
  //   function heartBlast() {
  //     return handleheartBlast.classList.toggle("heart-blast");
  //   }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
   //  if (state.active === status) {
   //    setState({ active: "" });
   //  }
  };

  useEffect(() => {
    const status =
      location?.pathname === "/" ? "dashboard" : (location?.pathname).slice(1);
      handleMenuActive(status);
  }, [location]);

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown as="div" className=" header-profile2 dropdown">
          <Dropdown.Toggle
            as="div"
            variant=""
            className=" i-false c-pointer"
            role="button"
            data-toggle="dropdown"
          >
            <div className="header-info2 d-flex align-items-center">
              {/*<img src={profile} width={20} alt="" />*/}
              <i className="fa fa-user fs-1"></i>
              <div className="d-flex align-items-center sidebar-info ms-1">
                <div>
                  <span className="font-w400 d-block">
                    {profileDetail.displayName}
                  </span>
                  <small className="text-end font-w400">
                    {profileDetail.roleName}
                  </small>
                </div>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            align="end"
            className=" dropdown-menu dropdown-menu-end"
          >
            {/*<Link to="/app-profile" className="dropdown-item ai-icon">
              <svg
                id="icon-user1"
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
              <span className="ms-2">Profile </span>
            </Link>
            <Link to="/email-inbox" className="dropdown-item ai-icon">
              <svg
                id="icon-inbox"
                xmlns="http://www.w3.org/2000/svg"
                className="text-success"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg>
              <span className="ms-2">Inbox </span>
            </Link>*/}
            <LogoutPage />
          </Dropdown.Menu>
        </Dropdown>
        <ul className="metismenu" id="menu">
          {menuList?.length > 0 &&
            menuList.map((data, index) => {
              let menuClass = data.classsChange;
              if (menuClass === "menu-title") {
                return (
                  <li className={menuClass} key={index}>
                    {data.title}
                  </li>
                );
              } else {
                return (
                  <li
                    className={` ${
                      state.active === data.to ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <>
                        <Link
                          to={"#"}
                          className="has-arrow"
                          //   onClick={() => {
                          //     handleMenuActive(data.to);
                          //   }}
                        >
                          <i className={data.iconStyle}></i>
                          <span className="nav-text">{data.title}</span>
                          <span className="badge badge-xs style-1 badge-danger">
                            {data.update}
                          </span>
                        </Link>
                        <Collapse
                          in={state.active === data.title ? true : false}
                        >
                          <ul
                            className={`${
                              menuClass === "mm-collapse" ? "mm-show" : ""
                            }`}
                          >
                            {data.content.map((submenu, subIndex) => (
                              <li
                                key={subIndex}
                                className={`${
                                  state.activeSubmenu === submenu.title
                                    ? "mm-active"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={submenu.to}
                                  className={submenu.hasMenu ? "has-arrow" : ""}
                                  onClick={() => {
                                    handleSubmenuActive(submenu.title);
                                  }}
                                >
                                  {submenu.title}
                                </Link>
                                <Collapse
                                  in={
                                    state.activeSubmenu === submenu.title
                                      ? true
                                      : false
                                  }
                                >
                                  <ul
                                    className={`${
                                      menuClass === "mm-collapse"
                                        ? "mm-show"
                                        : ""
                                    }`}
                                  >
                                    {submenu.content &&
                                      submenu.content.map(
                                        (subsubmenu, subsubIndex) => (
                                          <li key={subsubIndex}>
                                            <Link
                                              className={`${
                                                path === subsubmenu.to
                                                  ? "mm-active"
                                                  : ""
                                              }`}
                                              to={subsubmenu.to}
                                            >
                                              {subsubmenu.title}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </Collapse>
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      </>
                    ) : (
                      <>
                        {profileDetail.roleName === "Sub Recruiter" &&
                        data.title === "Manage Sub Recruiter" ? (
                          ""
                        ) : (
                          <Link
                            className={` ${
                              state.active === data.to ? "mm-active" : ""
                            }`}
                            to={data.to}
                            key={index}
                            //  onClick={() => {
                            //    handleMenuActive(data.to);
                            //  }}
                          >
                            <i className={data.iconStyle}></i>
                            <span className="nav-text">{data.title}</span>
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                );
              }
            })}
        </ul>
        <hr />
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
