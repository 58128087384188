import { SET_MENU } from "../actions/MenuActions";

const initialState = {
  menu: [],
};

export function MenuReducer(state = initialState, action) {
  if (action.type === SET_MENU) {
    return {
      ...state,
      menu: action.payload,
    };
  }

  return state;
}
